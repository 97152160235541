var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content pt-2"},[_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"columns infoService mt-3"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Code")]),_c('p',{staticClass:"has-text-weight-semibold"},[_vm._v(_vm._s(_vm.service.code))])]),(_vm.hasViewServiceProviderPermission)?_c('div',{staticClass:"column is-one-fifth"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Provider")]),_c('p',[_vm._v(" "+_vm._s(_vm.service.supplier_service && _vm.service.supplier_service.length ? _vm.service.supplier_service[0].name.value : _vm.service.name.value)+" ")])]):_vm._e(),_c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Type")]),_c('p',[_vm._v(" "+_vm._s(_vm.service.name_type_service.service_type_name)+" ")])]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("COU")]),_c('p',[_vm._v(" "+_vm._s(_vm.service.location && _vm.service.location.length > 0 ? _vm.service.location[0].location_code : "")+" ")])]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("DEST")]),_vm._l((_vm.service.location),function(location,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(location.location_code)+" ")])})],2),_c('div',{staticClass:"column is-one-fifth"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Title")]),_c('p',{staticClass:"has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.service.name.value)+" ")])]),_vm._m(0),_c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Pax(s)")]),_c('p',[_vm._v(_vm._s(_vm.numPax))])])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",attrs:{"is-room-selection-completed":_vm.isRoomSelectionCompleted}},[(
              (_vm.service.id_service_type === 2 ||
                _vm.service.id_service_type === 9) &&
              _vm.service.room
            )?_c('table',{staticClass:"table table is-hoverable table is-fullwidth"},[_c('thead',[_c('tr',[_c('td',{staticClass:"color-2 has-text-weight-medium",staticStyle:{"width":"20%"}},[_vm._v(" Room type ")]),_c('td',{staticClass:"color-2 has-text-weight-medium",staticStyle:{"width":"15%"}},[_vm._v(" Meal Plan ")]),(_vm.hasViewCostPricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium",staticStyle:{"width":"6%"}},[_vm._v(" Cost ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2",staticStyle:{"width":"2%"}}):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium",staticStyle:{"width":"8%"}},[_vm._v(" Sales ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-bold",staticStyle:{"width":"6%"}},[_vm._v(" Total ")]):_vm._e(),_c('td',{staticClass:"color-2 has-text-weight-medium",staticStyle:{"width":"8%"}},[_vm._v(" Room Qty. ")])])]),_c('tbody',_vm._l((_vm.service.room),function(room){return _c('tr',{key:room.id,class:_vm.getRowClass(room, _vm.service.room)},[_c('td',[_vm._v(_vm._s(room.name))]),_c('td',[_vm._v(_vm._s(room.includes.value))]),(_vm.hasViewCostPricePermission)?_c('td',{staticClass:"color-7"},[_vm._v(" "+_vm._s(_vm.getRoomCostCurrency(room))+" "+_vm._s(room && room.cost_service && room.cost_service[0].costs_ranges && room.cost_service[0].costs_ranges[0].cost ? room.cost_service[0].costs_ranges[0].cost : 0)+" ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',[(
                      room.price_service_sm &&
                      room.price_service_sm[0].price_service &&
                      room.price_service_sm[0].price_service[0] &&
                      room.price_service_sm[0].price_service[0].risk_price ===
                        1
                    )?_c('b-tooltip',{attrs:{"label":"Price calculated with risk","type":"is-dark"}},[_c('IconRisk',{staticClass:"heartIcon"})],1):_vm._e()],1):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',[_vm._v(" "+_vm._s(_vm.getPriceCurrency(_vm.service))+" "+_vm._s(room && room.price_service_sm && room.price_service_sm[0].price_service && room.price_service_sm[0].price_service[0].prices_ranges && room.price_service_sm[0].price_service[0].prices_ranges[0] ? room.price_service_sm[0].price_service[0] .prices_ranges[0].price : room && room.cost_service && room.cost_service[0].costs_ranges && room.cost_service[0].costs_ranges[0].cost ? room.cost_service[0].costs_ranges[0].cost : 0)+" ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.getPriceCurrency(_vm.service))+" "+_vm._s(_vm.calculateTotalRoom( room && room.price_service_sm && room.price_service_sm[0].price_service && room.price_service_sm[0].price_service[0] .prices_ranges && room.price_service_sm[0].price_service[0] .prices_ranges[0] ? room.price_service_sm[0].price_service[0] .prices_ranges[0].price : room && room.cost_service && room.cost_service[0].costs_ranges && room.cost_service[0].costs_ranges[0].cost ? room.cost_service[0].costs_ranges[0].cost : 0, room.qty ))+" ")]):_vm._e(),_c('td',[_c('b-select',{staticClass:"addServiceSelect",attrs:{"size":"is-small","expanded":""},on:{"input":function($event){return _vm.setRoomsValidation(_vm.service.room)}},model:{value:(room.qty),callback:function ($$v) {_vm.$set(room, "qty", _vm._n($$v))},expression:"room.qty"}},[_c('option',{domProps:{"value":0}},[_vm._v("0")]),_vm._l((_vm.serviceQty(room, _vm.service.room)),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)])}),0)]):_vm._e(),(
              _vm.service.id_service_type !== 2 &&
              _vm.service.id_service_type !== 9 &&
              _vm.service.prices_costs_margins
            )?_c('table',{staticClass:"table table is-hoverable table is-fullwidth"},[_c('thead',[_c('tr',[(_vm.hasViewServiceProviderPermission)?_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"12%"}},[_vm._v(" Provider ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"8%"}},[_vm._v(" Price Unit ")]):_vm._e(),(_vm.hasViewCostPricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"6%"}},[_vm._v(" Cost ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2",staticStyle:{"width":"2%"}}):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"12%"}},[_vm._v(" Sales "),_c('b-tooltip',{staticClass:"tooltipSales",attrs:{"size":"is-small","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b',[_vm._v("p.r:")]),_vm._v(" Price per range."),_c('br'),_c('b',[_vm._v("p.p:")]),_vm._v(" Price per person. ")]},proxy:true}],null,false,2379484281)},[_c('IconInfo',{staticClass:"infoIconSales color-4"})],1)],1):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"12%"}},[_vm._v(" Price Per Person ")]):_vm._e(),_c('td',{staticClass:"color-2 has-text-weight-medium has-text-centered",staticStyle:{"width":"8%"}},[_vm._v(" Qty. ")])])]),_c('tbody',_vm._l((_vm.service.prices_costs_margins
                  .service_supplier),function(supplier){return _c('tr',{key:supplier.id,class:_vm.getRowClass(
                    supplier,
                    _vm.service.prices_costs_margins.service_supplier
                  )},[(_vm.hasViewServiceProviderPermission)?_c('td',{staticClass:"has-text-centered",attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(supplier !== null && supplier.code)+" "),_c('b-tooltip',{attrs:{"label":"Favorite Provider"}},[(supplier.default_sup === 1)?_c('IconHeart',{staticClass:"heartIcon"}):_vm._e()],1)],1):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"has-text-weight-semibold text-center"},[_vm._v(" "+_vm._s(_vm.getPriceUnitBySupplierCode(_vm.service.id, supplier.code))+" ")]):_vm._e(),(_vm.hasViewCostPricePermission)?_c('td',{staticClass:"color-7 text-center",attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.getSupplierCostCurrency(_vm.service, supplier.code))+" "+_vm._s(_vm.getSupplierValue(_vm.service, supplier, "cost", ""))+" ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',[_c('b-tooltip',{attrs:{"label":"Price calculated with risk","type":"is-dark"}},[(
                        _vm.service.prices_costs_margins.risk_price &&
                        _vm.service.prices_costs_margins.risk_price === 1
                      )?_c('IconRisk',{staticClass:"heartIcon"}):_vm._e()],1)],1):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"has-text-weight-semibold has-text-centered"},[_vm._v(" "+_vm._s(_vm.getPriceCurrency(_vm.service))+" "+_vm._s(_vm.getServiceValue(_vm.service, supplier, "price"))+" "+_vm._s(_vm.getServiceValue(_vm.service, supplier, "individual") ? "p.p." : "p.r")+" ")]):_vm._e(),(_vm.hasViewSalePricePermission)?_c('td',{staticClass:"color-2 has-text-weight-semibold has-text-centered"},[_vm._v(" "+_vm._s(_vm.getPriceCurrency(_vm.service))+" "+_vm._s(_vm.getServiceValue(_vm.service, supplier, "price_unit"))+" p.p ")]):_vm._e(),_c('td',[_c('b-select',{staticClass:"addServiceSelect",attrs:{"size":"is-small","expanded":""},on:{"input":function($event){return _vm.changeServiceQty(
                        $event,
                        _vm.service,
                        supplier,
                        _vm.service.prices_costs_margins.service_supplier
                      )}},model:{value:(supplier.room_qty),callback:function ($$v) {_vm.$set(supplier, "room_qty", _vm._n($$v))},expression:"supplier.room_qty"}},[_c('option',{domProps:{"value":0}},[_vm._v("0")]),_vm._l((_vm.serviceQty(
                        supplier,
                        _vm.service.prices_costs_margins.service_supplier
                      )),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)])}),0)]):_vm._e(),(_vm.notAvaliableService(_vm.service))?_c('BaseNotAvaliable',{attrs:{"text":"Service not avaliable to be used"}}):_vm._e(),(!_vm.notAvaliableService(_vm.service))?_c('div',{staticClass:"is-flex is-justify-content-flex-end"},[_c('BaseButton',{class:_vm.focusedGuideBoxClass('', 21, false, function () { return _vm.index === 0; }),attrs:{"size":"small","loading":_vm.service.loading,"disabled":_vm.disableQuoteByStatus,"action":"tercery"},on:{"click":function($event){_vm.nextStep();
                _vm.createItineraryServices(_vm.service);}}},[_c('i',{attrs:{"slot":"icon"},slot:"icon"},[(_vm.disableQuoteByStatus)?_c('BaseButtonLock',{staticClass:"icon-check"}):_c('IconPlus',{staticClass:"icon is-small"})],1),_c('div',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Add Service")])])],1):_vm._e()],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"color-7 mb-2"},[_vm._v("Category")]),_c('p',[_vm._v("All")])])}]

export { render, staticRenderFns }